import route from '../../../vendor/tightenco/ziggy';
import {Ziggy} from '~/ziggy.js';

export class DsRoute {
    static route(name: string, params = undefined, absolute = undefined): string | undefined {
        return route(name, params, absolute, Ziggy);
    }

    static has_route(name: string) {
        // @ts-ignore
        return route(undefined, undefined, undefined, Ziggy).check(name);
    }

    static is_route(name: string, params = undefined) {
        // @ts-ignore
        return route(undefined, undefined, undefined, Ziggy).current(name, params);
    }

    static current(route_name: null | string | Array<string> = undefined) {
        if (route_name) {
            if (Array.isArray(route_name)) {
                for (let i = 0; i < route_name.length; i++) {
                    // @ts-ignore
                    if (route(undefined, undefined, undefined, Ziggy).current() === route_name[i]) {
                        return true;
                    }
                }
            }
            // @ts-ignore
            return route(undefined, undefined, undefined, Ziggy).current() === route_name;
        }
        // @ts-ignore
        return route(undefined, undefined, undefined, Ziggy).current();
    }
}
